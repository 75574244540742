export default {
  defaultMoneyFormat(value) {
    let money = value

    if (typeof money === 'string') {
      money = this.removeMoneyFormat(money)
    }

    return parseFloat(money).toLocaleString('en-US', {
      style: 'decimal',
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    })
  },

  removeMoneyFormat(value) {
    if (typeof value === 'string') {
      // แทนที่ตัวอักษรที่ไม่ใช่ตัวเลขด้วยค่าว่าง
      const money = parseFloat(value.replace(/[^0-9.]/g, ''))

      if (Number.isNaN(money)) {
        return 0
      }

      return money
    }

    return value
  },

  isZero(value) {
    let money

    if (typeof value === 'undefined') {
      return true
    }

    if (typeof value === 'string') {
      if (value === '') {
        return true
      }

      money = parseFloat(value.replace(/[^0-9.]/g, ''))
    }

    if (typeof value === 'number') {
      money = parseFloat(value)
    }

    return money === 0.00
  },
}
