<template>
  <b-modal
    id="transfer-modal"
    ref="transfer-modal"
    title="Transfer Money To Another Wallet"
    size="lg"
    centered
    hide-footer
    no-close-on-backdrop
    @shown="doLoadData"
  >
    <b-overlay no-wrap :show="showOverlay" />
    <validation-observer ref="observer">
      <b-form @submit.stop.prevent>

        <!-- !! Table Header -->
        <b-row>
          <b-col class="mt-1" cols="12">
            <HeroInputText
              id="filter"
              v-model="tableConfig.filter"
              placeholder="Search"
              @input="doTableFilter(500)"
            />
          </b-col>
        </b-row>

        <!-- !! Table -->
        <b-row class="mb-1">
          <b-col cols="12" style="height: calc(40vh); overflow: hidden;">
            <b-table
              show-empty
              striped
              sticky-header="100%"
              :bordered="true"
              :small="true"
              :responsive="true"
              :items="items"
              :fields="tableConfig.fields"
              :per-page="0"
              :sort-by="tableConfig.sortBy"
              :sort-direction="tableConfig.sortDirection"
              :no-sort-reset="true"
              :no-local-sorting="true"
              @sort-changed="doTableSort"
            >

              <template #cell(action)="data">
                <div style="width: 10px;">
                  <b-form-radio
                    v-model="destinationObjectId"
                    :value="data.item.id"
                    @change="selectDestinationObject(data.item)"
                  />
                </div>
              </template>

              <template #cell(name)="data">
                <div style="text-align: left;">
                  {{ data.item.name }}
                </div>
              </template>

              <template #cell(type)="data">
                <div style="text-align: center;">
                  {{ data.item.type }}
                </div>
              </template>

              <template #cell(balance)="data">
                <div style="text-align: right;">
                  {{ moneyService.defaultMoneyFormat(data.item.balance) }}
                </div>
              </template>

            </b-table>
          </b-col>
        </b-row>

        <b-row>
          <!-- !! Source Budget -->
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="wallet budget"
              rules="required"
            >
              <HeroVueSelect
                id="source_wallet_budget_id"
                v-model="sourceWalletBudgetId"
                label="Source Budget"
                column="6"
                :clearable="false"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
                :options="$store.getters['SourceWalletBudgetOptions/getOptions']"
              />
            </validation-provider>
          </b-col>

          <!-- !! Transfer Amount -->
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="amount"
              rules="required"
            >
              <HeroInputNumber
                id="amount"
                v-model="transferAmount"
                label="Transfer Amount"
                column="6"
                :readonly="false"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
                custom-style="text-align: right;"
              />
            </validation-provider>
          </b-col>

          <!-- !! Description -->
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="description"
              rules="required"
            >
              <HeroTextarea
                id="description"
                v-model="description"
                placeholder="Description"
                :required="true"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>
        </b-row>

        <!-- !! Footer -->
        <hr>
        <b-row>
          <b-col cols="6" sm="4">
            <HeroButtonAction type="button" variant="outline-secondary" @click="doCloseModal">
              Back
            </HeroButtonAction>
          </b-col>
          <b-col cols="6" offset-sm="4" sm="4">
            <HeroButtonAction
              type="button"
              variant="primary"
              :disabled="sourceWalletBudgetId === '' || description === '' || destinationObjectId === '' || moneyService.isZero(transferAmount)"
              @click="validationForm"
            >
              Transfer
            </HeroButtonAction>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import moment from 'moment'
import store from '@/store'
import axios from '@axios'
import AxiosConfig from '@/services/AxiosConfigs'
import ErrorService from '@/services/ErrorService'
import SweetAlert from '@/services/SweetAlert'
import MoneyService from '@/services/MoneyService'
import {
  BOverlay,
  BForm,
  BRow,
  BCol,
  BTable,
  BFormRadio,
} from 'bootstrap-vue'
import HeroInputText from '@/views/components/form/inputs/HeroInputText.vue'
import HeroVueSelect from '@/views/components/form/selects/HeroVueSelect.vue'
import HeroTextarea from '@/views/components/form/inputs/HeroTextarea.vue'
import HeroInputNumber from '@/views/components/form/inputs/HeroInputNumber.vue'
import HeroButtonAction from '@/views/components/form/buttons/HeroButtonAction.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  required,
  integer,
} from '@validations'
import Toastification from '@/services/Toastification'

export default {
  name: 'TransferModal',
  components: {
    BOverlay,
    BForm,
    BRow,
    BCol,
    BTable,
    BFormRadio,
    HeroInputText,
    HeroVueSelect,
    HeroTextarea,
    HeroInputNumber,
    HeroButtonAction,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    sourceObjectId: {
      type: String,
      required: true,
    },
    sourceObjectType: {
      type: String,
      required: true,
    },
    sourceObjectBalance: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      destinationObjectId: '',
      destinationObject: {
        id: '',
        name: '',
        type: 'Opportunity',
        balance: '0.00',
      },
      sourceWalletBudgetId: '',
      transferAmount: '',
      description: '',

      items: [],
      tableConfig: {
        filter: '',
        currentPage: 1,
        perPage: 25,
        perPageOptions: [5, 10, 25, 50, 75, 100],
        totalRows: 0,
        sortBy: 'name',
        sortDirection: 'asc',
        timeInterval: moment(),
        fields: [
          {
            label: '',
            key: 'action',
            sortable: false,
          },
          {
            label: 'Destination',
            key: 'name',
            sortable: false,
          },
          {
            label: 'Type',
            key: 'type',
            sortable: false,
          },
          {
            label: 'Balance',
            key: 'balance',
            sortable: false,
          },
        ],
      },

      // Service
      moneyService: MoneyService,

      // Overlay
      showOverlay: false,

      // Validation
      required,
      integer,
    }
  },
  methods: {
    doCloseModal() {
      this.destinationObjectId = ''
      this.destinationObject = {
        id: '',
        name: '',
        type: 'Opportunity',
        balance: '0.00',
      }
      this.sourceWalletBudgetId = ''
      this.transferAmount = 0.00
      this.description = ''

      this.$refs['transfer-modal'].hide()
    },

    doTableFilter(timeout) {
      setTimeout(() => {
        if (moment().diff(this.tableConfig.timeInterval, 'milliseconds') > timeout) {
          this.tableConfig.timeInterval = moment()
          this.doLoadData()
        }
      }, timeout)
    },

    doTableSort(event) {
      this.tableConfig.sortBy = event.sortBy
      this.tableConfig.sortDirection = event.sortDesc ? 'desc' : 'asc'
      this.doLoadData()
    },

    async doLoadData() {
      this.showOverlay = true

      await this.$store.commit('SourceWalletBudgetOptions/setSourceId', this.sourceObjectId)
      await this.$store.commit('SourceWalletBudgetOptions/setSourceType', this.sourceObjectType)
      await this.$store.commit('SourceWalletBudgetOptions/setDoUpdate', true)
      await this.$store.dispatch('SourceWalletBudgetOptions/fetchOptions')

      if (this.destinationObjectId !== '') {
        await this.selectDestinationObject()
      }

      const token = store.getters['heroAiAuthentications/sanctumToken']
      const axiosConfig = AxiosConfig.getJsonWithToken(token)

      try {
        const {
          filter,
        } = this.tableConfig

        const response = await axios.get(`wallet-family/get-wallets-by-object-id/${this.sourceObjectId}?filter=${filter}`, axiosConfig)
        this.items = response.data.data
      } catch (error) {
        if (error.response.status === 401) {
          await this.$router.push({ name: 'heroai-logout' })
        }

        if (error.response.status === 404) {
          await this.$router.push({ name: 'page-not-found' })
        }

        await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
      }

      this.showOverlay = false
    },

    // eslint-disable-next-line consistent-return
    async validationForm() {
      let alertResult
      let toastObject

      const token = store.getters['heroAiAuthentications/sanctumToken']
      const axiosConfig = AxiosConfig.getJsonWithToken(token)
      const validationResult = await this.$refs.observer.validate()

      if (validationResult) {
        if (this.sourceWalletBudgetId === '') {
          toastObject = Toastification.getContentError('Please choose source budget.')
          this.$toast(toastObject)
          return false
        }

        const remainAmount = this.$store.getters['SourceWalletBudgetOptions/getRemainAmount'](this.sourceWalletBudgetId)

        if (parseFloat(this.transferAmount) > parseFloat(remainAmount)) {
          toastObject = Toastification.getContentError('Transfer amount is higher than available source budget.')
          this.$toast(toastObject)
          return false
        }

        this.showOverlay = true

        const body = {
          description: `[Manual Transfer] ${this.description}`,
          destination_object_id: this.destinationObject.id,
          destination_object_type: this.destinationObject.type,
          source_object_id: this.sourceObjectId,
          source_object_type: this.sourceObjectType,
          transfer_amount: MoneyService.removeMoneyFormat(this.transferAmount),
          source_wallet_budget_id: this.sourceWalletBudgetId,
        }

        try {
          const response = await axios.post('wallet-family/transactions/', body, axiosConfig)

          alertResult = await this.$swal({ ...SweetAlert.success, text: response.data.message })

          if (alertResult.value) {
            // this.$refs['transfer-modal'].hide()
            this.$emit('success')
            this.doCloseModal()
          }
        } catch (error) {
          await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
        }

        this.showOverlay = false
      }
    },

    async selectDestinationObject(object) {
      this.destinationObject = object
    },

    async loadDestinationWalletBudget() {
      await this.$store.commit('DestinationWalletBudgetOptions/setSourceId', this.destinationObject.id)
      await this.$store.commit('DestinationWalletBudgetOptions/setSourceType', this.destinationObject.type)
      await this.$store.dispatch('DestinationWalletBudgetOptions/fetchOptions')
    },
  },
}
</script>
