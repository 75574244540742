<template>
  <b-form-group
    :label-for="id"
    :label-cols-md="column"
  >
    <template v-if="label" v-slot:label>
      <span style="font-size: 1rem; font-weight: 600;">
        {{ label }} <span v-if="required" class="text-danger">*</span>
      </span>
    </template>
    <b-input-group
      class="input-group-merge"
      :class="state ? 'is-valid' : state === null ? '' : 'is-invalid'"
    >
      <b-form-input
        :id="id"
        ref="inputMoney"
        v-model="formValue"
        :placeholder="placeholder"
        type="text"
        :state="state"
        :readonly="readonly"
        :disabled="disabled"
        :style="customStyle"
        @keyup.enter="formatMoney"
        @blur="formatMoney"
        @focus="unformatMoney"
      />
      <b-input-group-append v-if="!readonly && !disabled && !required && clearable && value.length > 0" is-text>
        <feather-icon
          icon="XIcon"
          class="cursor-pointer"
          @click="$emit('input', '0.00')"
        />
      </b-input-group-append>
    </b-input-group>
    <b-form-valid-feedback :state="state">
      {{ validFeedback }}
    </b-form-valid-feedback>
    <b-form-invalid-feedback :state="state">
      {{ invalidFeedback }}
    </b-form-invalid-feedback>
  </b-form-group>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormValidFeedback,
  BInputGroup,
  BInputGroupAppend,
} from 'bootstrap-vue'

export default {
  components: {
    BFormGroup,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BFormValidFeedback,
    BFormInvalidFeedback,
  },
  props: {
    // Got value from v-model
    value: {
      type: [String, Number],
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    column: {
      type: String,
      required: false,
      default: '0',
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    clearable: {
      type: Boolean,
      required: false,
      default: true,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    state: {
      type: Boolean,
      required: false,
      default: null,
    },
    validFeedback: {
      type: String,
      required: false,
      default: '',
    },
    invalidFeedback: {
      type: String,
      required: false,
      default: '',
    },
    customStyle: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    formValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  methods: {
    unformatMoney() {
      if (this.readonly) {
        return
      }

      let money = this.value

      if (typeof this.value === 'string') {
        // แทนที่ตัวอักษรที่ไม่ใช่ตัวเลขด้วยค่าว่าง
        money = money.replace(/[^0-9.]/g, '')
      }

      this.formValue = parseFloat(money)
    },
    formatMoney() {
      let money = this.value

      if (typeof money === 'string') {
        // แทนที่ตัวอักษรที่ไม่ใช่ตัวเลขด้วยค่าว่าง
        money = money.replace(/[^0-9.]/g, '')
      }

      // ถ้าผู้ใช้ไม่ได้พิมพ์ตัวเลขเข้ามาเลยจะได้ค่าว่าง
      if (money === '') {
        money = 0.00
      }

      // แปลงผลลัพธ์เป็นค่าเงิน
      if (money !== '') {
        this.formValue = parseFloat(money)
          .toLocaleString('en-US', {
            style: 'decimal',
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })
      }
    },
  },
}
</script>
