<template>
  <div>
    <b-card-actions
      ref="cardA"
      no-actions
      no-body
    >
      <b-card class="my-2" no-body>
        <!-- !! Table Header -->
        <div class="mx-1">
          <b-row>
            <b-col cols="3">
              <HeroVueSelect
                id="wallet-type"
                v-model="walletType"
                label="Wallet Type"
                :clearable="false"
                :options="walletOptions"
              />
            </b-col>

            <!-- Type: Opportunity -->
            <b-col v-if="walletType === 'Opportunity'" cols="4">
              <HeroVueSelect
                id="account-id"
                v-model="selectAccountId"
                label="Account Name"
                :clearable="true"
                :loading="$store.getters['AccountOptions/getIsLoading']"
                :options="$store.getters['AccountOptions/getOptions']"
                @input="changeAccountId"
              />
            </b-col>
            <b-col v-if="walletType === 'Opportunity'" cols="5">
              <HeroVueSelect
                v-if="walletType === 'Opportunity'"
                id="opportunity-id"
                v-model="selectOpportunityId"
                label="Opportunity Name"
                :clearable="false"
                :loading="$store.getters['OpportunityOptionsWalletPage/getIsLoading']"
                :options="$store.getters['OpportunityOptionsWalletPage/getOptions']"
              />
            </b-col>

            <!-- Type: Campaign -->
            <b-col v-if="walletType === 'Campaign'" cols="9">
              <HeroVueSelect
                id="campaign-id"
                v-model="selectCampaignId"
                label="Campaign Name"
                :clearable="false"
                :loading="$store.getters['CampaignOptions/getIsLoading']"
                :options="$store.getters['CampaignOptions/getOptions']"
              />
            </b-col>
            <b-col cols="12" sm="3">
              <HeroInputMoney
                id="balance"
                v-model="balance"
                label="Balance"
                :readonly="true"
                custom-style="background-color: white; text-align: right;"
              />
            </b-col>
            <b-col
              v-if="$can('Transfer', 'Wallets')"
              cols="12"
              sm="2"
            >
              <HeroButtonModal
                v-b-modal="'transfer-modal'"
                type="button"
                variant="danger"
                class="mt-2"
                :disabled="disableTransferButton()"
              >
                Transfer
              </HeroButtonModal>
            </b-col>
          </b-row>
        </div>

        <!-- !! Table -->
        <b-row class="my-1">
          <b-col cols="12" style="height: calc(100vh - 440px); overflow: hidden;">
            <b-table
              show-empty
              striped
              sticky-header="100%"
              :responsive="true"
              :items="items"
              :fields="tableConfig.fields"
              :per-page="0"
              :sort-by="tableConfig.sortBy"
              :sort-direction="tableConfig.sortDirection"
              :no-sort-reset="true"
              :no-local-sorting="true"
              @sort-changed="doTableSort"
            >
              <template #cell(created_at)="data">
                <div style="width: 150px;">
                  {{ formatEnglishDateTimeDefault(data.item.created_at) }}
                </div>
              </template>

              <template #cell(source_object_name)="data">
                <div style="width: 250px;" v-html="convertObjectName(data, 'source')" />
              </template>

              <template #cell(destination_object_name)="data">
                <div style="width: 250px;" v-html="convertObjectName(data, 'destination')" />
              </template>

              <template #cell(transfer_amount)="data">
                <div style="width: 100px; text-align: right;">
                  {{ formatMoney(data.item.transfer_amount) }}
                </div>
              </template>

              <template #cell(source_balance)="data">
                <div style="width: 100px; text-align: right;">
                  {{ formatMoney(data.item.source_balance) }}
                </div>
              </template>

              <template #cell(destination_balance)="data">
                <div style="width: 100px; text-align: right;">{{
                  formatMoney(data.item.destination_balance)
                }}
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>

        <!-- !! Table Footer -->
        <div class="mx-1 mb-1">
          <b-row>
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
              <HeroTablePagination
                v-model="tableConfig.currentPage"
                :per-page="tableConfig.perPage"
                :total-rows="tableConfig.totalRows"
                @input="doTableFilter(100)"
              />
            </b-col>
            <b-col cols="12" sm="6" class="d-flex align-items-top justify-content-center justify-content-sm-end">
              <HeroTableStatus :per-page="tableConfig.perPage" :total-rows="tableConfig.totalRows" />
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-card-actions>

    <!-- !! Modal -->
    <TransferModal :source-object-id="sourceObjectId" :source-object-type="walletType" :source-object-balance="balance" @success="doLoadData" />
  </div>
</template>

<script>
import moment from 'moment'
import store from '@/store'
import axios from '@axios'
import AxiosConfig from '@/services/AxiosConfigs'
import ErrorService from '@/services/ErrorService'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import HeroVueSelect from '@/views/components/form/selects/HeroVueSelect.vue'
import HeroInputMoney from '@/views/components/form/inputs/HeroInputMoney.vue'
import HeroTablePagination from '@/views/components/table/HeroTablePagination.vue'
import HeroTableStatus from '@/views/components/table/HeroTableStatus.vue'
import { BTable } from 'bootstrap-vue'
import SweetAlert from '@/services/SweetAlert'
import DataFormatService from '@/services/DataFormatService'
import TransferModal from '@/views/heroai/wallets/components/TransferModal.vue'
import HeroButtonModal from '@/views/components/form/buttons/HeroButtonModal.vue'

export default {
  name: 'WalletList',
  components: {
    TransferModal,
    BTable,
    BCardActions,
    HeroVueSelect,
    HeroInputMoney,
    HeroTablePagination,
    HeroTableStatus,
    HeroButtonModal,
  },
  data() {
    return {
      walletType: 'Opportunity',
      sourceObjectId: '',
      selectAccountId: '',
      selectCampaignId: '',
      selectOpportunityId: '',
      balance: '0.00',
      currency: 'THB',

      items: [],
      tableConfig: {
        filter: '',
        currentPage: 1,
        perPage: 25,
        perPageOptions: [5, 10, 25, 50, 75, 100],
        totalRows: 0,
        sortBy: 'name',
        sortDirection: 'asc',
        timeInterval: moment(),
        fields: [
          {
            label: 'Date Time',
            key: 'created_at',
            sortable: false,
          },
          {
            label: 'Description',
            key: 'description',
            sortable: false,
          },
          {
            label: 'From',
            key: 'source_object_name',
            sortable: false,
          },
          {
            label: 'To',
            key: 'destination_object_name',
            sortable: false,
          },
          {
            label: 'By',
            key: 'created_by_name',
            sortable: false,
          },
          {
            label: 'Transfer Amount',
            key: 'transfer_amount',
            sortable: false,
          },
          {
            label: 'Original Balance',
            key: 'source_balance',
            sortable: false,
          },
          {
            label: 'New Balance',
            key: 'destination_balance',
            sortable: false,
          },
        ],
      },

      // Options
      walletOptions: [
        {
          value: 'Campaign',
          text: 'Campaign',
        },
        {
          value: 'Opportunity',
          text: 'Opportunity',
        },
      ],
    }
  },
  watch: {
    walletType() {
      if (this.walletType === 'Opportunity') {
        this.selectCampaignId = ''
        this.balance = '0.00'
      }

      if (this.walletType === 'Campaign') {
        this.selectAccountId = ''
        this.selectOpportunityId = ''
        this.balance = '0.00'
      }

      this.items = []
    },
    async selectCampaignId() {
      if (this.selectCampaignId !== '') {
        await this.doLoadData()
      }

      this.disableTransferButton()
    },
    async selectOpportunityId() {
      if (this.selectOpportunityId !== '') {
        await this.doLoadData()
      }

      this.disableTransferButton()
    },
  },
  async mounted() {
    this.$refs.cardA.showLoading = true

    if (this.$route.query.walletType === 'Campaign') {
      this.walletType = 'Campaign'
    }

    if (this.walletType === 'Opportunity') {
      if (this.$route.query.accountId) {
        this.selectAccountId = this.$route.query.accountId
        await this.changeAccountId()
      }

      if (this.$route.query.objectId) {
        this.selectOpportunityId = this.$route.query.objectId
      }
    }

    if (this.walletType === 'Campaign') {
      if (this.$route.query.objectId) {
        this.selectCampaignId = this.$route.query.objectId
      }
    }

    await Promise.all([
      this.$store.dispatch('AccountOptions/fetchOptions'),
      this.$store.dispatch('CampaignOptions/fetchOptions'),
    ])

    this.$refs.cardA.showLoading = false
  },
  methods: {
    formatMoney: DataFormatService.formatMoney,
    formatEnglishDateTimeDefault: DataFormatService.formatEnglishDateTimeDefault,
    doTableFilter(timeout) {
      setTimeout(() => {
        if (moment()
          .diff(this.tableConfig.timeInterval, 'milliseconds') > timeout) {
          this.tableConfig.timeInterval = moment()
          this.doLoadData()
        }
      }, timeout)
    },

    doTableSort(event) {
      this.tableConfig.sortBy = event.sortBy
      this.tableConfig.sortDirection = event.sortDesc ? 'desc' : 'asc'
      this.doLoadData()
    },

    async doLoadData() {
      this.$refs.cardA.showLoading = true

      const token = store.getters['heroAiAuthentications/sanctumToken']
      const axiosConfig = AxiosConfig.getJsonWithToken(token)

      try {
        const {
          perPage,
          currentPage,
        } = this.tableConfig

        let objectId = ''

        if (this.walletType === 'Campaign') {
          objectId = this.selectCampaignId
        }

        if (this.walletType === 'Opportunity') {
          objectId = this.selectOpportunityId
        }

        const url = `wallet-family/transactions/${objectId}?perPage=${perPage}&page=${currentPage}`
        const response = await axios.get(url, axiosConfig)

        this.balance = this.formatMoney(response.data.balance)
        this.currency = response.data.currency
        this.tableConfig.currentPage = Number(response.data.data.current_page)
        this.tableConfig.perPage = Number(response.data.data.per_page)
        this.tableConfig.totalRows = Number(response.data.data.total)
        this.items = response.data.data.data

        this.sourceObjectId = objectId
      } catch (error) {
        await this.$swal({
          ...SweetAlert.error,
          html: ErrorService.extractError(error),
        })

        if (error.response.status === 401) {
          await this.$router.push({ name: 'heroai-logout' })
        }

        this.tableConfig.totalRows = 0
      }

      this.$refs.cardA.showLoading = false
    },

    convertObjectName(data, type) {
      let objectId = ''
      let objectType = ''
      let objectName = ''

      if (type === 'source') {
        objectId = data.item.source_object_id
        objectType = data.item.source_object_type
        objectName = data.item.source_object_name ?? ''
      } else {
        objectId = data.item.destination_object_id
        objectType = data.item.destination_object_type
        objectName = data.item.destination_object_name ?? ''
      }

      const name = `[${objectType}] ${objectName}`.trim()

      if (objectType === 'Campaign') {
        return `<a href="/campaigns/${objectId}" target="_blank">${name}</a>`
      }

      return `<a href="/opportunities/${objectId}" target="_blank">${name}</a>`
    },

    async changeAccountId() {
      this.selectOpportunityId = ''
      this.balance = '0.00'

      await this.$store.commit('OpportunityOptionsWalletPage/setAccountId', this.selectAccountId)
      await this.$store.dispatch('OpportunityOptionsWalletPage/fetchOptions')
      this.disableTransferButton()
    },

    disableTransferButton() {
      if (this.walletType === 'Opportunity') {
        return this.selectOpportunityId === ''
      }

      if (this.walletType === 'Campaign') {
        return this.selectCampaignId === ''
      }

      return true
    },
  },
}
</script>
